import React, {useEffect} from 'react';

const Office = () => {
    useEffect(() => {
        window.location.href = '/company/overview/#office'
    }, []);

    return null;
};

export default Office;
